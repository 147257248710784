<template>
  <div class="publishProduct">
    <div class="page_title">
      发布产品
    </div>
    <div class="page_content">
      <div class="form_box">
        <el-form :model="sumitObj" :rules="rules" ref="sumitObj" label-width="100px" class="demo-sumitObj">
          <el-form-item label="产品名称:" prop="name">
            <el-input v-model="sumitObj.name" type="text" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="产品类型:" prop="type">
            <el-select v-model="sumitObj.category_id" placeholder="请选择产品类型">
              <el-option :label="item.name" :value="item.id" v-for="(item,index) in $store.state.categoryConfig.product" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="价格类型:" prop="price_type">
            <el-radio-group v-model="sumitObj.price_type">
              <el-radio :label="1">定价</el-radio>
              <el-radio :label="2">面议</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="单价（元）:" prop="price">
            <el-input v-model="sumitObj.price" placeholder="请输入价格" type="number"></el-input>
          </el-form-item>
          <el-form-item label="是否含税:" prop="if_tax">
            <el-radio-group v-model="sumitObj.if_tax">
              <el-radio :label="1">含税</el-radio>
              <el-radio :label="0">不含税</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系人:" prop="contact">
            <el-input v-model="sumitObj.contact" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="contact_phone">
            <el-input v-model="sumitObj.contact_phone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="供应地区:" prop="area_code">
            <RegionSelect :value="sumitObj.area_code" @changeRegion="changeRegion"></RegionSelect>
          </el-form-item>
          <el-form-item label="详细地址:" prop="address">
            <el-input v-model="sumitObj.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <div class="page_area_title">
            <span class="must_point">*</span> 产品详情
          </div>
          <div class="text_area_box">
            <el-form-item prop="desc">
              <el-input type="textarea" v-model="sumitObj.desc" placeholder="可简单介绍一下产品信息"></el-input>
            </el-form-item>
          </div>
          <div class="page_area_title">
            <span class="must_point">*</span> 上传附件
          </div>
          <div class="upload_img_box">
            <div class="upload_img_tags_text">
              如产品照片等
            </div>
            <el-form-item prop="images">
              <uploadImageList ref="uploadImageList" @success="uploadSuccess">
              </uploadImageList>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <button class="blue_btn" @click="submitForm('sumitObj')">发布</button>
    </div>
  </div>
</template>
  
<script>
import RegionSelect from '../../../components/regionSelect.vue'
import uploadImageList from '@/components/uploadImage/uploadImageList.vue';
import { validatePass } from '@/utils';

export default {
  components: {
    RegionSelect,
    uploadImageList
  },
  data () {
    return {

      props: { value: 'id', label: 'name', children: 'child' },
      pageAuth: 'publish_product',
      sumitObj: {
        name: '',
        type: '',
        price_type: 1,
        if_tax: 1,
        contact: '',
        contact_phone: '',
        category_id: [],
        area: '',
        area_code: [],
        address: '',
        desc: '',
        images: []
      },
      rules: {
        name: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '请输入价格', trigger: 'blur' },
        ],
        if_tax: [
          { required: true, message: '请选择是否含税', trigger: 'change' },
        ],
        price_type: [
          { required: true, message: '请输入选择价格类型', trigger: 'change' },
        ],
        category_id: [
          { required: true, message: '请选择产品类型', trigger: 'change' },
        ],

        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        contact_phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        area_code: [
          { required: true, message: '请选择供应地区', trigger: 'blur' }
        ],
        images: [
          { required: true, message: '请上传附件', trigger: 'change' }
        ],
        address: [
          { required: false, message: '请输入详细地址', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请输入产品详情', trigger: 'blur' },
        ],
      },

    }
  },
  methods: {
    uploadSuccess (val) {
      this.$set(this.sumitObj, 'images', val);
    },
    changeRegion (val) {
      console.log(val, 'sasa');
      this.sumitObj.area_code = val.area_code;
      this.sumitObj.area = val.area;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ...this.sumitObj
          }
          params.images += '';
          params.area_code = `${this.sumitObj.area_code[0]}/${this.sumitObj.area_code[1]}`;
          this.$api.publishProduct(params).then(res => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.sumitObj = {
                name: '',
                type: '',
                price_type: 1,
                if_tax: 1,
                contact: '',
                contact_phone: '',
                category_id: [],
                area: '',
                area_code: [],
                address: '',
                desc: '',
                images: []
              }
              this.$refs.uploadImageList.clearImageList();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  },
  mounted () {
    this.checkMemberDealAuth(this.pageAuth);
  },
  watch: {

  },
}
</script>
  
<style lang='scss' scoped>
.publishProduct {
  background-color: #fff;
  padding: 26px;
  .page_title {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    margin-bottom: 30px;
  }
  .job_type {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .job_type_item {
      width: 76px;
      height: 31px;
      background: #f6f6f6;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      border: 1px solid #f6f6f6;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      box-sizing: border-box;
      margin-right: 7px;
      cursor: pointer;
    }
    .active_type_item {
      background: #f3f5ff;
      opacity: 1;
      border: 1px solid #3f63ff;
      color: #3f63ff;
    }
  }
  .tips {
    margin: 0 0 26px;
  }
  .page_content {
    .page_area_title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 13px;
    }

    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .form_box {
      margin-bottom: 23px;
      /deep/ .el-form-item {
        label {
          width: 110px !important;
        }
        .el-form-item__content {
          margin-left: 110px !important;
        }
      }
      /deep/ .el-select {
        width: 100%;
      }
      .upload_img_box {
        margin-bottom: 50px;
        /deep/ .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
        .upload_img_tags_text {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 10px;
        }
      }
      .text_area_box {
        /deep/ .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
        /deep/ .el-textarea textarea {
          height: 106px;
        }
      }
    }
  }
}
</style>
